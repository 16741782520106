import { Trans } from "@lingui/macro"
import { Box, Button, Typography } from "@mui/material"
import { useSetChain } from "@web3-onboard/react"
import React from "react"

import type { Network } from "../../../utils/NetworkHelpers"

interface IProps {
  network: Network
}

const SwitchNetwork: React.FC<IProps> = ({ network }) => {
  const [, setChain] = useSetChain()

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Typography textAlign="center" fontSize={24} mt={1}>
        <Trans>Please switch to correct network</Trans>
      </Typography>
      <Typography textAlign="center" fontSize={16} mt={2}>
        <Trans>
          Your wallet is currently connected to an incorrect network. To continue, please switch to
        </Trans>
        <span style={{ marginLeft: "8px", color: "#8C8C8C" }}>{network.label}</span>
      </Typography>
      <Box
        mt={3}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setChain({ chainId: `0x${network.chainId.toString(16)}` })
          }}
        >
          <Trans>Switch to {network.label}</Trans>
        </Button>
      </Box>
    </Box>
  )
}

export default SwitchNetwork
